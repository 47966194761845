import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

function CuentasCorporativasModal({isOpen, onClose}) {

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                Cuentas corporativas
            </ModalHeader>
            <ModalBody>
                <p>
                    Para atencion personalizada, envia un correo electrónico a <b>ventas@gestorescpd.chihuahua.gob.mx</b> para que un agente se ponga en contacto contigo.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onClose}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default CuentasCorporativasModal