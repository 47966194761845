import React, {useState, useEffect, useContext} from 'react';
import { httpGet, httpPost } from '../shared/Config';
import Section from '../shared/Section';
import Table from '../shared/Table'
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Alert, Collapse, FormGroup, Label, Input } from 'reactstrap';
import UserTokenModal from '../users/UserTokenModal';
import DeleteUserModal from '../users/DeleteUserModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {ConsoleDispatch, types, dispatchMiddleware} from '../reducers/reducers'
import { useToggle } from '../shared/Hooks';

import DateFormat from '../shared/DateFormat'
import moment from 'moment';

import StripeSubscriptionModal from '../payments/StripeSubscriptionModal';
import SpeiSubscriptionModal from '../payments/SpeiSubscriptionModal';
import ConfirmUnsubscribeModal from '../payments/ConfirmUnsubscribeModal';
import ConfirmReactivateModal from '../payments/ConfirmReactivateModal';

function ActivateGlobalConsumption({orgId}) {

    const [currentStatus, setCurrentStatus] = useState(false);
    const [checkDone, setCheckDone] = useState(false);
    const [working, setWorking] = useState(false); 

    function checkStatus(){
        setWorking(true);
        async function fetchStatus(){
            try{
                const response = await httpGet(`/orgs/${orgId}/globalConsumption`);
                if(response) {
                    setCurrentStatus(response.data.data.status);
                }
            } catch(error) {

            }
            setCheckDone(true);
            setWorking(false);
        }
        fetchStatus();
    }

    function handleSwitch(){
        setWorking(true);
        async function switchGlobalConsumption(){
            try{
                const response = await httpGet(`/orgs/${orgId}/switchGlobalConsumption`);
                if(response.data && response.data.data) {
                    setCurrentStatus(response.data.data.status);
                } else {
                    console.error(response.data.error);
                }
            } catch(error) {

            }
            setWorking(false);
        }
        switchGlobalConsumption();
    }

    useEffect(() => {
        checkStatus();
    }, []);

    return(
        <div>
            <div className="container">
                {
                    checkDone ? <React.Fragment>
                        <div className="row align-items-center">
                            <div className="col">
                                <div>
                                    <div>Consumo de documentos globales:</div>
                                    {
                                        currentStatus ? <React.Fragment><b>Activado</b></React.Fragment> : <React.Fragment><b>Desactivado</b></React.Fragment>
                                    }
                                </div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end">
                                    <Button outline 
                                        onClick={handleSwitch}
                                        color="primary"
                                        disabled={working}>
                                        {
                                            currentStatus ? <React.Fragment>Desactivar</React.Fragment> : <React.Fragment>Activar</React.Fragment>
                                        }
                                    </Button>
                                </div>                
                            </div>
                        </div>    
                    </React.Fragment> : <React.Fragment>
                        <div className="row align-items-center">
                            <div className="col">
                                <div>Hubo un error tratando de llenar esta seccion. Intente mas tarde.</div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

function RemainingDocuments({remaining, planData, orgId, onDocumentsAdded}) {

    const [modal, setModal] = useState({
        open: false
    })    

    function handleModalCancel() {
        setModal({open:false})
    }

    function handleAddDocsClick() {
        setModal({open:true})
    }

    function handleDocumentsAdded() {
        setModal({open:false});
        onDocumentsAdded();
    }

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="container">
            <div className="row align-items-center">
                <div className="col">
                    <div>Documentos restantes: {remaining.documents}</div>
                </div>
                {/*<div className="col">
                    <div className="row justify-content-end">
                    <Button outline 
                        onClick={handleAddDocsClick}
                        color="primary">Agregar</Button>
                    </div>                
                </div>*/}
            </div>     
            {/*<BuyDocumentsModal 
                orgId={orgId}
                isOpen={modal.open}
                onCancel={handleModalCancel}
                onSaved={handleDocumentsAdded}
            />*/}
        </div>           
    )
}

function RemainingTimestamps({remaining, planData, orgId, onTimestampsAdded}) {

    const [status, setStatus] = useState({
        working: false,
        error: null,
        loading: false,
        stampsActive: false
    })

    useEffect(() => {
        async function fetchConfig() {
            try {
                setStatus({working: true, error: null, loading: true})
                const response = await httpGet(`/orgs/${orgId}/config/nom151FinalStamp`);
                //var planDataObj = planData;
                if(response.data.data){
                    const dataJson = response.data.data;
                    if(dataJson.stamp === true) {
                        setStatus({working: false, error: null, loading: false, stampsActive: true })
                    } else {
                        setStatus({working: false, error: null, loading: false, stampsActive: false })
                    }
                }
                //setStamper(serverConfig);
            } catch(e) {
                setStatus({working: false, error: 'No se pudo obtener la configuración, por favor reintente más tarde', loading: false})
            }            
        }
        fetchConfig();
    }, [orgId])

    /*const [modal, setModal] = useState({
        open: false
    })    

    function handleModalCancel() {
        setModal({open:false})
    }

    function handleAddTimestampsClick() {
        setModal({open:true})
    }*/

    function handleActivateStamps() {
        async function fetchOrgData() {
            const response = await httpGet(`/orgs/${orgId}/config/activateTimestamps`)

            if(response.data) {
                const dataJson = response.data;
                if(dataJson.success) {
                    setStatus({working: false, error: null, loading: false, stampsActive: true });
                }
            }
            //setOrg(response.data);
        }
        fetchOrgData();
    }

    /*function handleTimestampsAdded() {
        setModal({open:false});
        onTimestampsAdded();
    }*/

    useEffect(() => {
        window.scrollTo(0,0);
    }, [])

    return (
        <div className="container">
            {
                status.stampsActive ? <React.Fragment>
                {
                    planData.plan.name === "PERSONAL" ? <React.Fragment>
                        Plan 'Personal' de sellos NOM151 activo.
                    </React.Fragment> : null
                }
                {
                    planData.plan.name === "CORPORATIVO" ? <React.Fragment>
                        Plan 'ON DEMAND' de sellos NOM151 activo.
                    </React.Fragment> : null
                }
                {
                    planData.plan.name === "EMPRESARIAL" ? <React.Fragment>
                        Plan 'Empresarial' de sellos NOM151 activo.
                    </React.Fragment> : null
                }
                </React.Fragment> : <React.Fragment>
                    <div>Las sellos NOM151 estan desactivadas para esta cuenta.</div>
                    <div className="row justify-content-end" >
                        <Button outline 
                            onClick={handleActivateStamps}
                            color="primary">Activar sellos NOM151</Button>
                    </div>
                </React.Fragment>
            }
            {/*{
            remaining.limit && remaining.timestampPlan === "LIMITED" ? <React.Fragment>
            <div className="row align-items-center">
                <div className="col">
                    <div>Sellos NOM151 restantes: {remaining.timestamps}</div>
                </div>
                <div className="col">
                    <div className="row justify-content-end">
                    <Button outline 
                        onClick={handleAddTimestampsClick}
                    color="primary">Agregar</Button>
                    </div>
                </div>
            </div>     
            <BuyTimestampsModal 
                orgId={orgId}
                isOpen={modal.open}
                onCancel={handleModalCancel}
                onSaved={handleTimestampsAdded}
            />
            </React.Fragment> : <React.Fragment>
            {
                remaining.timestampPlan === "UNLIMITED" ? <React.Fragment>Su plan de sellos NOM151 es ilimitado, no hay necesidad de agregar sellos NOM151.</React.Fragment> : null
            } 
            </React.Fragment>
        }*/}
        </div>           
    )
}


function Organization({match}) {

    const [org, setOrg] = useState({});
    const [orgError, setOrgError] = useState(false);
    const [orgData, setOrgData] = useState({});
    
    const [fetchComplete, setFetchComplete] = useState(false);
    //const [companyData, setCompanyData] = useState({});
    const [subType, setSubType] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [subStatus, setSubStatus] = useState('');
    const [errorCheckingActive, setErrorCheckingActive] = useState(false);
    const [validUntil, setValidUntil] = useState(false);
    const [validUntilNull, setValidUntilNull] = useState(false);
    
    const [waitingPayment, setWaitingPayment] = useState(false);

    const [planData, setPlanData] = useState({
        plan: {}
    });
    const [users, setUsers] = useState([])
    const [remainingUsers, setRemainingUsers] = useState({});
    const [remainingDocs, setRemainingDocs] = useState({});
    const [remainingTimestamps, setRemainingTimestamps] = useState({});
    const [sameEmail, setSameEmail] = useState(false);
    
    const [userModal, setUserModal] = useState({
        open: false
    })
    const [deleteUserModal, setDeleteUserModal] = useState({
        open: false,
        user: {}
    })
    const [invoiceData, setInvoiceData] = useState({
        working: false
    })
    const [supportData, setSupportData] = useState({
        working: false
    })
    
    const [workingAdmin, setWorkingAdmin] = useState({
        working: false
    })

    const [reactivateModal, setReactivateModal] = useState({
        open: false
    });

    const [unsubModal, setUnsubModal] = useState({
        open: false
    });

    const [unsubSpeiModal, setUnsubSpeiModal] = useState({
        open: false
    });

    const [subModal, setSubModal] = useState({
        open: false
    });

    const [speiModal, setSpeiModal] = useState({
        open: false
    });

    const [buyState, setBuyState] = useState({
        subType: '',
        newOrgId: match.params.id
    });

    const [unsubSuccess, setUnsubSuccess] = useState(false);
    const [unsubError, setUnsubError] = useState(false);
    const [unsubWorking, setUnsubWorking] = useState(false);

    const [reactivateSuccess, setReactivateSuccess] = useState(false);
    const [reactivateError, setReactivateError] = useState(false);
    const [reactivateWorking, setReactivateWorking] = useState(false);
    
    const [departmentName, setDepartmentName] = useState('');

    const [subActive, setSubActive] = useState(false);

    const [showConfTsa, toggleShowConfTsa] = useToggle(false)
    const [error, setError] = useState('');

    const dispatch = useContext(ConsoleDispatch)

    useEffect(() => {
        async function fetchOrgData() {
            
            /*const responseCompany = await httpGet(`/companyData`);
            let data = responseCompany.data.data;
            setCompanyData((current) => {
                return {...current, data};
             });*/
             
            try{
                const response = await httpGet(`/orgs/${match.params.id}`)
                if(response.data) {
                    setOrg(response.data);
                }

            } catch(error){
                setOrgError(true);
            }

            //setOrgData(response.data);
        }
        fetchOrgData();
    }, [match.params.id])

    useEffect(() => {
        async function fetchPlan() {
            const response = await httpGet(`/orgs/${match.params.id}/plan`);
            
            if(response) {
                setPlanData(response.data);
                setBuyState({...buyState, subType: response.data.plan.name})

                try{
                    if(response.data.plan.name === "PERSONAL") {
                        setSubType(true);

                        if(org.subscriptionType === "STRIPE"){
                            const responseActive = await httpGet(`/payments/${match.params.id}/checkIfActiveStripe`)

                            if(responseActive) {
                                let status = responseActive.data.data.status;
                                setIsActive(status);

                                if(responseActive.data.data.statusType) {
                                    setSubStatus(responseActive.data.data.statusType);
                                }

                                if(responseActive.data.data.validUntil) {
                                    setValidUntil(responseActive.data.data.validUntil);
                                } else {
                                    setValidUntil(null);
                                }
                            } else {
                                setIsActive(false);
                                setErrorCheckingActive(true);
                            }
                        } else if(org.subscriptionType === "SPEI") {
                            const responseActive = await httpGet(`/payments/${match.params.id}/checkIfActiveSPEI`)

                            if(responseActive) {
                                let status = responseActive.data.data.status;
                                setIsActive(status);

                                if(responseActive.data.data.statusType) {
                                    setSubStatus(responseActive.data.data.statusType);
                                }

                                if(responseActive.data.data.validUntil) {
                                    if(responseActive.data.data.validUntil === "null"){
                                        setValidUntilNull(true);
                                    } else {
                                        setValidUntil(responseActive.data.data.validUntil);
                                    }
                                } else {
                                    setValidUntil(null);
                                }

                                if(responseActive.data.data.waitingPayment) {
                                    setWaitingPayment(true);
                                }
                            } else {
                                setIsActive(false);
                                setErrorCheckingActive(true);
                            }
                        } else {
                            setIsActive(true);
                            setSubType(false);
                        }
                    } else {
                        setIsActive(true);
                        setSubType(false);
                    }
                    
                    setFetchComplete(true);
                } catch(error) {
                    setIsActive(false);
                    setErrorCheckingActive(true);
                }
            }
        }

        async function fetchUsers() {
            const response = await httpGet(`/orgs/${match.params.id}/users`);
            setUsers(response.data.map(u => ({...u, ...JSON.parse(u.json)})));

        }      
        dispatch({type:types.UPDATE_PAYMENT_INFO})
        if(org.subscriptionType){
            fetchPlan();
            
            fetchUsers();
        }
        
    }, [org])    


    useEffect(() => {
        async function fetchRemainingUsers() {
            const response = await httpGet(`/orgs/${match.params.id}/users/remaining`);
            setRemainingUsers({...response.data})
        }        

        fetchRemainingUsers();
        fetchRemainingDocuments();
        fetchRemainingTimestamps();
    }, [users.length]) 

    useEffect(() => {
        async function fetchInvoiceData() {
            if(!org.invoiceDataId) {
                return;
            }
            const response = await httpGet(`/invoiceData/${org.invoiceDataId}`);
            setInvoiceData({...response.data, working: false});
            
            if(response) {

                const responseEmail = await httpGet(`/orgs/getCompanyEmail`);
                if(responseEmail) {
                    if(responseEmail.data.data.companyEmail === response.data.contactEmail) {
                        setSameEmail(true);
                    } else {
                        setSameEmail(false);
                    }
                }
            } 
        }
        fetchInvoiceData();
    }, [org.invoiceDataId])

    useEffect(() => {
        async function fetchSupportData() {
            if(!org.supportContactId) {
                return;
            }
            const response = await httpGet(`/supportData/${org.supportContactId}`);
            setSupportData({...response.data, working: false});
        }
        fetchSupportData();
    }, [org.supportContactId])

    async function fetchRemainingDocuments() {
        const response = await httpGet(`/orgs/${match.params.id}/documents/remaining`);
        console.log('remaining docs: ');
        console.log(JSON.stringify(response.data));
        setRemainingDocs({...response.data});
    }

    function handleOnDocumentsAdded() {
        fetchRemainingDocuments();
    }

    async function fetchRemainingTimestamps() {
        const response = await httpGet(`/orgs/${match.params.id}/timestamps/remaining`);
        console.log('remaining timestamps: ');
        console.log(JSON.stringify(response.data));
        setRemainingTimestamps({...response.data});
    }

    function handleOnTimestampsAdded() {
        fetchRemainingTimestamps();
    }


    function handleUserAdd() {
        setUserModal({...userModal, open:true})
    }

    function handleUserSave(user) {
        setUsers([...users, user])
        setUserModal({open:false})
    }

    function handleDeleteUserClick(user) {
        return function() {
            setDeleteUserModal({
                user, open:true
            })
        }
    }

    function handleUserDeleted(user) {
        setUsers(users.filter(u => u.id !== user.id))
        setDeleteUserModal({user:{}, open:false})
    }

    async function handleInvoiceDataChange(invoiceDataId) {
        const invoiceDataIdInt = parseInt(invoiceDataId);
        if(invoiceData.id && invoiceData.id !== invoiceDataIdInt) {
            setInvoiceData({...invoiceData, working:true});
            await httpPost(`/orgs/${match.params.id}/invoiceData`, {
                invoiceDataId: invoiceDataIdInt
            })
            setInvoiceData({...invoiceData, working:false});
        }             
        setOrg((current) => {
         return {...current, invoiceDataId}
        });
    }

    async function handleSupportDataChange(supportContactId) {
        const supportContactIdInt = parseInt(supportContactId);
        if(supportData.id && supportData.id !== supportContactIdInt) {
            setSupportData({...supportData, working:true});
            await httpPost(`/orgs/${match.params.id}/supportData`, {
                supportContactId: supportContactIdInt
            })
            setSupportData({...supportData, working:false});
        }             
        setOrg((current) => {
           return {...current, supportContactId: supportContactIdInt};
        });
    }

    function requestChangeDepartmentName() {
        setError('');
        async function changeDepartmentName() {
            let trimName = departmentName.trim();
            const response = await httpPost(`/orgs/changeDepartmentName`, {departmentName: trimName, id: match.params.id});
            if(response) {
                if(response.data.status === "success") {
                    setOrg({...org, organizationName: trimName});
                    setDepartmentName('');
                } else if(response.data.status === "name_found") {
                    setError("Ya existe una organización con ese nombre, por favor elija otro.");
                } else if(response.data.status === "too_long") {
                    setError("El nombre de la organización debe ser de 24 caracteres de largo o menos, por favor elija otro.");
                }
            }
        }
        changeDepartmentName();
    }

    function handleDepartmentName(attr) {
        return function(event) {
            if(event.target.value.length < 25) {
                setDepartmentName(event.target.value);
            }
        }
    }

    function handleAdminChange(e, user) {
        async function adminToggle() {
            try{
                setWorkingAdmin({working: true});
                const response = await httpPost(`/orgs/${match.params.id}/users/admin`, user);
                //if(response.data && response.data.success) {        
                if(response.data && response.data.data) {  
                    //const json = JSON.parse(response.data);
                    setUsers(users.map(userIt => {
                        if(user.id === userIt.id) {
                            return {...userIt, adminCore: response.data.data.status}
                        }
                        return userIt;
                    }));
                } else {

                }
            } catch(error) {

            }
            
            setWorkingAdmin({working: false})
        }
        adminToggle();
    }

    function speiInfoModal() {
        setSpeiModal({open:true});
    }

    function closeSpeiInfoModal() {    
        setSpeiModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
        window.location.reload();
    }


    function activateDepartment() {
        setSubModal({open:true});
    }

    function activateDepartmentSPEI() {
        setSubModal({open:true});
    }

    function cancelSub() {    
        setSubModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
    }

    function cancelSubSPEI() {    
        setSubModal({open:false}); // closes the modal, the user won't be able to open the department until the sub is complete
    }

    function successSub() {
        setSubModal({open:false});
        window.location.reload();
    }

    function unsubscribe() {
        setUnsubModal({open:true});
    }

    function unsubModalCancel() {
        setUnsubModal({open:false});
        window.location.reload();
    }


    function unsubConfirm() {
        setUnsubWorking(true);
        setUnsubError(false);

        async function unsub(){
            if(org.subscriptionType === "STRIPE") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/cancelSubscription/`);
                    if(response) {
                        setUnsubSuccess(true);
                    } else {
                        setUnsubError(true);
                    }
                }
                catch(error) {
                    setUnsubError(true);
                }
            } else if(org.subscriptionType === "SPEI") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/cancelSPEISubscription/`);
                    if(response) {
                        setUnsubSuccess(true);
                    } else {
                        setUnsubError(true);
                    }
                }
                catch(error) {
                    setUnsubError(true);
                }
            }

            setUnsubWorking(false);
        }
        unsub();
    }

    function reactivateModalOpen() {
        setReactivateModal({open:true});
    }

    function reactivateModalCancel(){
        setReactivateModal({open:false});
        window.location.reload();
    }

    function reactivateConfirm() {
        setReactivateWorking(true);
        setReactivateError(false);

        async function reactivate(){
            if(org.subscriptionType === "STRIPE") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/reactivateSubscription/`);
                    if(response) {
                        setReactivateSuccess(true);
                    } else {
                        setReactivateError(true);
                    }
                }
                catch(error) {
                    setReactivateError(true);
                }
            } else if(org.subscriptionType === "SPEI") {
                try{
                    const response = await httpPost(`/payments/subscription/${match.params.id}/reactivateSPEISubscription/`);
                    if(response) {
                        setReactivateSuccess(true);
                    } else {
                        setReactivateError(true);
                    }
                }
                catch(error) {
                    setReactivateError(true);
                }
            }
            setReactivateWorking(false);
        }
        reactivate();
    }

    return (
        <div>
            {
                !orgError && fetchComplete ? <React.Fragment>
                    {
                        isActive ? <React.Fragment> {/* aun no se obtiene la informacion de la subscripcion */}
                            {
                                subStatus === "ACTIVE" ? <React.Fragment>
                                        Este departmento esta activo.
                                        <br/>
                                    </React.Fragment> : null
                            }
                            {
                                subStatus === "PROCESSING" ? <React.Fragment>
                                    <Section>
                                        Su activación/cancelación aun esta siendo procesada. En cuanto se complete, le haremos saber su fecha de corte.
                                        <br/>
                                        Debido a que aun no es su fecha de corte, puede seguir usando el departmento normalmente hasta ese dia.
                                        <br/>
                                    </Section>
                                </React.Fragment> : null
                            }
                            {
                                subStatus === "ACTIVE_NO_RENEWAL" && !waitingPayment  ? <React.Fragment>
                                    <Section>
                                        <div className="column">
                                            Este departamento tiene suscripción inactiva, no se renovara cuando llegue el dia de corte.
                                            <br />
                                            No podra usar este departemento despues de esta fecha.
                                            <br/>
                                        </div>
                                        <div className="col">
                                            <div className="row justify-content-end">
                                                <Button  outline color="danger" onClick={reactivateModalOpen}>
                                                    Reactivar suscripción
                                                </Button>
                                            </div>
                                        </div>
                                    </Section>
                                    <ConfirmReactivateModal isOpen={reactivateModal.open}
                                        onCancel={reactivateModalCancel}
                                        onOk={reactivateConfirm}
                                        title="Reactivar suscripción"
                                        reactivateComplete={reactivateSuccess}
                                        reactivateWorking={reactivateWorking}>
                                            <p>Está por reactivar su suscripción. Se cobrará de nuevo al día de corte.</p>
                                            <p>¿Deseas continuar?</p>
                                            {
                                                reactivateSuccess ? <React.Fragment>
                                                    <span style={{fontWeight: 'bold', color: '#166fd0'}}>Reactivación completada</span>
                                                </React.Fragment> : null
                                            }
                                            {
                                                reactivateError ? <React.Fragment>
                                                    <span style={{fontWeight: 'bold', color: 'red'}}>Error al tratar de reactivar su suscripción, intente mas tarde</span>
                                                </React.Fragment> : null
                                            }
                                    </ConfirmReactivateModal>
                                </React.Fragment> : null
                            }
                            {
                                subType ? <React.Fragment> 
                                    {
                                        validUntilNull ? <React.Fragment>
                                            La fecha de corte no ha sido determinada aún. Fecha limite de pago: <DateFormat>{moment(org.createdAt).add(3, 'days')}</DateFormat>
                                        </React.Fragment> : <React.Fragment>
                                            {
                                                validUntil ? <React.Fragment>
                                                    La fecha de corte de su suscripción es: <DateFormat>{moment(validUntil)}</DateFormat>
                                                </React.Fragment> : <React.Fragment>
                                                    No pudimos obtener su fecha de corte. Vuelva a entrar más tarde.
                                                </React.Fragment>    
                                            }
                                        </React.Fragment>
                                    }
                                </React.Fragment> : null
                            }
                            <br/>
                            <br/>
                            <h3>Departamento: {org.organizationName}</h3>
                            <br/>
                            {
                                users.length === 0 ?
                                <Alert color="primary" style={{cursor:'pointer'}} onClick={handleUserAdd}>
                                    Todavía no tienes ningún usuario. Da de alta un usuario en tu organización
                                </Alert>   :
                                null
                            }
                            {
                                sameEmail ? <React.Fragment>
                                    <Alert color="primary">
                                        Su correo electrónico de facturación es el mismo al del administrador (correo electrónico con el que accesa al portal de Administrador), le sugerimos cambiarlo
                                    </Alert>
                                </React.Fragment> : <React.Fragment>

                                </React.Fragment>
                            }
                            <h4>Plan {planData.plan.uiName}</h4>
                            {/*<Section>            
                                <div className="column">
                                    {
                                        planData.plan.uiName === "Personal" ? <React.Fragment>
                                            <div className="container">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        Plan de documentos personal activo.
                                                    </div>
                                                </div>     
                                            </div>  
                                        </React.Fragment> : <React.Fragment>
                                            {
                                                remainingDocs.limit ? 
                                                <RemainingDocuments 
                                                    orgId={match.params.id}
                                                    remaining={remainingDocs}
                                                    planData={planData}
                                                    onDocumentsAdded={handleOnDocumentsAdded}/> : null
                                            }
                                        </React.Fragment>
                                    }                    
                                </div>
                            </Section>*/}
                            {/*<Section>       
                                <div className="column">                    
                                    <RemainingTimestamps 
                                        orgId={match.params.id}
                                        remaining={remainingTimestamps}
                                        planData={planData}
                                        onTimestampsAdded={handleOnTimestampsAdded}/>
                                </div>
                            </Section>*/}
                            <br/>
                            <Section>
                                <FormGroup check>
                                    <Label check>
                                        Cambiar nombre de departamento (Nombre actual: {org.organizationName})
                                    </Label>
                                    <div className="d-flex">
                                        <Input style={{width: '30%', marginTop:'10px', marginRight: '10px'}} type="input" id="departmentName" value={departmentName} onChange={handleDepartmentName('dep')} />
                                        <Button style={{marginTop:'10px'}} color="primary" onClick={requestChangeDepartmentName}>Cambiar</Button>
                                    </div>
                                </FormGroup>
                                <br/>
                                {
                                     error ? <React.Fragment><span style={{fontWeight: 'bold', color: 'red'}}>{error}</span></React.Fragment> : null
                                }
                            </Section>
                            {/*{
                                planData.plan.name === "CORPORATIVO" ? <React.Fragment>
                                    <Section>
                                        <ActivateGlobalConsumption orgId={match.params.id} />
                                    </Section>
                                </React.Fragment> : null
                            }
                            <br/>*/}
                            {/*<Section>
                                <h4>Configuración</h4>
                                <br/>
                                <TimestampConfiguration orgId={match.params.id}></TimestampConfiguration>
                            </Section>*/}
                            <br/>
                            {/*{
                                org.invoiceDataId ? <React.Fragment>
                                    <Section>
                                        <h4>Facturación</h4>
                                        <br/>
                                        <div className="column">
                                            <SelectInvoiceData
                                                value={org.invoiceDataId}
                                                onChange={handleInvoiceDataChange}
                                                disabled={invoiceData.working}/>
                                            <p>
                                                Nombre: {invoiceData.name} <br/>
                                                Correo: {invoiceData.email} <br/>
                                                Código postal: {invoiceData.postalCode}                 
                                            </p>                                      
                                        </div>
                                    </Section>
                                </React.Fragment> : null
                            }
                            {
                                org.supportContactId ? <React.Fragment>
                                    <Section>
                                        <h4>Soporte</h4>
                                        <br/>
                                        <div className="column">
                                            <SelectSupportData
                                                value={org.supportContactId}
                                                onChange={handleSupportDataChange}
                                                disabled={supportData.working}/>
                                            <p>
                                                Nombre: {supportData.supportName} <br/>
                                                Correo: {supportData.supportEmail} <br/>
                                                Telefono: {supportData.supportPhone}                 
                                            </p>                                      
                                        </div>
                                    </Section>
                                </React.Fragment> : null
                            }
                            <br/>*/}
                            <Section>
                                <h4>Usuarios</h4>
                                <br/>
                                {
                                    planData.plan.name !== "PERSONAL" ?                                 
                                        <div className="row justify-content-end">
                                            <Button color="primary" onClick={handleUserAdd} disabled={planData.plan.limitsUsers && remainingUsers.users === 0 && !planData.plan.payForUsers}>Agregar</Button>
                                        </div> : null
                                }
                                <Table collection={users} noData="Sin usuarios">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Correo</th>
                                            <th>RFC</th>
                                            <th>Validado</th>
                                            <th>¿Admin?</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.map((u, i) => (
                                                <tr key={u.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{u.email}</td>
                                                    <td>
                                                        {
                                                            u.certData != null ? !u.certData.includes('@') ? u.certData : '' : null
                                                        }
                                                    </td>
                                                    {
                                                        u.signed === true ? <td>Si</td> : <td>No</td>
                                                    }
                                                    <td>
                                                    <FormGroup check>
                                                        <Input disabled={workingAdmin.working || !u.canDelete} type="checkbox" id="adminCore" value={u.adminCore} checked={u.adminCore} onChange={(e) =>handleAdminChange(e, u)} />{' '}
                                                    </FormGroup>
                                                    </td>
                                                    {
                                                        u.canDelete ?
                                                            <td style={{textAlign:'right'}}>
                                                                <Button outline color="danger" onClick={handleDeleteUserClick(u)}>
                                                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                                                </Button>
                                                            </td> : null
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </Section>
                            {
                                subType ? <React.Fragment>
                                    {
                                        subStatus === "ACTIVE" && !waitingPayment ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    Si cancela su suscripción, podra seguir utilizando del departamento hasta que llegue su dia de corte
                                                    <br />
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button  outline color="danger" onClick={unsubscribe}>
                                                            Cancelar suscripción
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>                            
                                            <ConfirmUnsubscribeModal isOpen={unsubModal.open}
                                                onCancel={unsubModalCancel}
                                                onOk={unsubConfirm}
                                                title="Cancelar suscripción"
                                                unsubComplete={unsubSuccess}
                                                unsubWorking={unsubWorking}>
                                                    <p>Está por cancelar su suscripción, podra seguir usando el departamento hasta el dia de corte.</p>
                                                    <p>¿Deseas continuar?</p>
                                                    {
                                                        unsubSuccess ? <React.Fragment>
                                                            <span style={{fontWeight: 'bold', color: '#166fd0'}}>Cancelación completada</span>
                                                        </React.Fragment> : null
                                                    }
                                                    {
                                                        unsubError ? <React.Fragment>
                                                            <span style={{fontWeight: 'bold', color: 'red'}}>Error al tratar de cancelar su suscripción, intente mas tarde</span>
                                                        </React.Fragment> : null
                                                    }
                                            </ConfirmUnsubscribeModal>
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                            <UserTokenModal isOpen={userModal.open}
                                firstUser={users.length === 0}
                                onCancel={() => setUserModal({...userModal, open:false})}
                                onSaved={handleUserSave}
                                orgId={match.params.id}
                                planData={planData}
                            />
                            <DeleteUserModal isOpen={deleteUserModal.open}
                                onCancel={() => setDeleteUserModal({...deleteUserModal, open:false})}
                                onDeleted={handleUserDeleted}
                                user={deleteUserModal.user}
                                orgId={match.params.id}/>
                            {/*<StripeSubscriptionModal 
                                isOpen={subModal.open}
                                buyState={buyState}
                                onCancel={cancelSub}
                                onStripeApproved={successSub}
                            />*/}
                        </React.Fragment> : <React.Fragment>
                            {
                                subStatus === "PAST_DUE" ? <React.Fragment>
                                    <h3>Departamento: {org.organizationName}</h3>
                                    <br/>
                                    <h4>Plan {planData.plan.uiName}</h4>
                                    <br/>
                                    {
                                        org.subscriptionType === "STRIPE" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    Este departmento esta inactivo. La fecha de corte de su suscripción fue: <DateFormat>{moment(validUntil)}</DateFormat>
                                                    <br />
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button  outline color="danger" onClick={reactivateModalOpen}>
                                                            Reactivar suscripción
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <ConfirmReactivateModal isOpen={reactivateModal.open}
                                                onCancel={reactivateModalCancel}
                                                onOk={reactivateConfirm}
                                                title="Reactivar suscripción"
                                                reactivateComplete={reactivateSuccess}
                                                reactivateWorking={reactivateWorking}>
                                                    <p>Está por reactivar su suscripción. Se cobrará de nuevo al día de corte.</p>
                                                    <p>¿Deseas continuar?</p>
                                                    {
                                                        reactivateSuccess ? <span style={{fontWeight: 'bold', color: '#166fd0'}}>Reactivación completada</span> : null
                                                    }
                                                    {
                                                        reactivateError ? <span style={{fontWeight: 'bold', color: 'red'}}>Error al tratar de reactivar su suscripción, intente mas tarde</span> : null
                                                    }
                                            </ConfirmReactivateModal>
                                        </React.Fragment> : null
                                    }
                                    {
                                        org.subscriptionType === "SPEI" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    No se completo el pago a tiempo, tu suscripción ha sido cancelada automáticamente. 
                                                    Revise la información del pago que debe realizar. 
                                                    <br/>
                                                    La fecha de corte de su suscripción fue: <DateFormat>{moment(validUntil)}</DateFormat>
                                                    <br />
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline 
                                                            onClick={speiInfoModal}
                                                            color="primary">Revisar información de pago SPEI
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <SpeiSubscriptionModal 
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                            {
                                subStatus === "NO_SUB" ? <React.Fragment>
                                    <h3>Departamento: {org.organizationName}</h3>
                                    <br/>
                                    <h4>Plan {planData.plan.uiName}</h4>
                                    <br/>
                                    {
                                        org.subscriptionType === "STRIPE" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    No existe una suscripción para este departamento.
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button style={{marginRight: '5px'}} outline 
                                                            onClick={activateDepartment}
                                                            color="primary">Activar departamento pagando con Stripe
                                                        </Button>
                                                        <Button outline 
                                                            onClick={speiInfoModal}
                                                            color="primary">Activar departamento pagando con SPEI
                                                        </Button>
                                                    </div>                                                    
                                                </div>
                                            </Section>
                                            <StripeSubscriptionModal 
                                                isOpen={subModal.open}
                                                buyState={buyState}
                                                onCancel={cancelSub}
                                                onStripeApproved={successSub}
                                            />
                                            <SpeiSubscriptionModal 
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                    {
                                        org.subscriptionType === "SPEI" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    No hay suscripción detectada para este departamento. Revise la información del pago que debe realizar.
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline 
                                                            onClick={speiInfoModal}
                                                            color="primary">Revisar información de pago SPEI
                                                        </Button>
                                                        <br/>
                                                        <br/>

                                                    </div>
                                                </div>
                                            </Section>
                                            <SpeiSubscriptionModal 
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                            {
                                subStatus === "CANCELLED" ? <React.Fragment>
                                    <h3>Departamento: {org.organizationName}</h3>
                                    <br/>
                                    <h4>Plan {planData.plan.uiName}</h4>
                                    <br/>
                                    {
                                        org.subscriptionType === "STRIPE" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    Esta suscripción fue cancelada. No se reactivará automáticamente al llegar la fecha de corte.
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline 
                                                            onClick={activateDepartment}
                                                            color="primary">Iniciar nueva suscripción
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <StripeSubscriptionModal 
                                                isOpen={subModal.open}
                                                buyState={buyState}
                                                onCancel={cancelSub}
                                                onStripeApproved={successSub}
                                            />
                                        </React.Fragment> : null
                                    }
                                    {
                                        org.subscriptionType === "SPEI" ? <React.Fragment>
                                            <Section>
                                                <div className="column">
                                                    Este departamento esta desactivado. Revise la información del pago que debe realizar.
                                                    <br/>
                                                </div>
                                                <div className="col">
                                                    <div className="row justify-content-end">
                                                        <Button outline 
                                                            onClick={speiInfoModal}
                                                            color="primary">Revisar información de pago SPEI
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Section>
                                            <SpeiSubscriptionModal 
                                                isOpen={speiModal.open}
                                                buyState={buyState}
                                                onCancel={closeSpeiInfoModal}
                                                id={match.params.id}
                                            />
                                        </React.Fragment> : null
                                    }
                                </React.Fragment> : null
                            }
                        </React.Fragment>
                    }
                </React.Fragment> : null
            }
            
        </div>
    )
}

export default Organization