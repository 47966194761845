import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBuilding, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons'
import PersonalInfoModal from '../plans/PersonalInfoModal'
import EmpresarialInfoModal from '../plans/EmpresrialInfoModal'
import CorporativoInfoModal from '../plans/CorporativoInfoModal'


function PlanIcon({icon, name, value, selected, onClick, onMoreInfoClick, personalPrice}) {
    function handleClick() {
        onClick(value);
    }

    function handleMoreInfoClick() {
        onMoreInfoClick(value);
    }

    return (
        <div className={`column PlanIcon ${selected ? 'Selected' : ''}`} onClick={handleClick}>
            <FontAwesomeIcon className="MenuIcon" icon={icon}/>
            <div>{name}</div>
            <div 
                style={{color:'#007bff'}}
                onClick={handleMoreInfoClick}>Más info</div>
        </div>
    )
}

function SelectPlan({value, onChange, mode, personalPrice}) {

    const [selected, setSelected] = useState({name:value})
    const [personalModal, setPersonalModal] = useState({
        isOpen: false
    })
    /*const [empresarialModal, setEmpresarialModal] = useState({
        isOpen: false
    })*/
    const [corporativoModal, setCorporativoModal] = useState({
        isOpen: false
    })

    function handleClick(val) {
        setSelected({name: val})
        onChange(val)
    }

    return (
        <React.Fragment>            
            {/*<h4>Selecciona un plan:</h4>*/}
            <div className="row justify-content-around">
                {/*
                    mode === "ALL" ? <React.Fragment>
                        <PlanIcon icon={faUser} 
                            name="Personal" 
                            selected={'PERSONAL' === selected.name}
                            value="PERSONAL"
                            onClick={handleClick}
                            onMoreInfoClick={() => setPersonalModal({isOpen:true})}></PlanIcon>
                    </React.Fragment> : null
                */}
                <PlanIcon icon={faBuilding} 
                    name="ON DEMAND" 
                    selected={'CORPORATIVO' === selected.name}
                    value="CORPORATIVO"
                    onClick={handleClick}
                    onMoreInfoClick={() => setCorporativoModal({isOpen:true})}></PlanIcon>
            </div>
            <br/>
            {/*<p>
                En todos los planes puede haber un número ilimitado de firmantes. Lo que
                varía es el número de iniciadores, que son aquellas personas que pueden subir
                un documento para firmar.
            </p>*/}
            {/*<p style={{textAlign:'center'}}>
                <a href="http://www.gestorescpd.chihuahua.gob.mx/precios" target="_blank">Comparar planes</a>
            </p>*/}
            <br/>
            {/*<PersonalInfoModal 
                isOpen={personalModal.isOpen}
                onClose={() => setPersonalModal({isOpen:false})}
                personalPrice={personalPrice}/>*/}
            <CorporativoInfoModal 
                isOpen={corporativoModal.isOpen}
                onClose={() => setCorporativoModal({isOpen:false})}/>

        </React.Fragment>
    )
}

export default SelectPlan
