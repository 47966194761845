import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {httpGet} from './shared/Config';

function LoginToken({match, history}) {

    const [status, setStatus] = useState('Verificando...')

    useEffect(() => {
        async function verifyToken() {
            const response = await httpGet(`/login/${match.params.token}`);
            if(response.data && response.data.success) {
                if(response.data.data.jwt) {
                    localStorage.setItem('jwt', response.data.data.jwt);
                    history.push('/console/orgs')
                } else if(response.data.data.type) {
                    let type = response.data.data.type;
                    if(response.data.data.status === "error") {
                        setStatus(
                            <p>
                                El link no es válido.
                            </p>
                        );
                    } else if(response.data.data.status === "past_due") {
                        setStatus(
                            <p>
                                El link ha expirado. Comuníquese con el administrador para que genere otro.
                            </p>
                        );
                    } else if (response.data.data.status === "done") {
                        if(type === "registerEmail") {
                            setStatus(
                                <p>
                                    Correo electrónico registrado. En unos momentos, sera redireccionado a Firmamex.
                                </p>
                            );

                            const timeout = setTimeout(async () => {
                                window.location.href = 'https://gestorescpd.chihuahua.gob.mx/pdf/default/';
                            }, 2000);
                            return () => {
                                clearTimeout(timeout);
                            }
                            //history.push('/console/emailRegistered');
                        } else if(type === "userAdd") {
                            //history.push('/console/userAdd');
                            setStatus(
                                <p>
                                    Usuario agregado con exito. En unos momentos, sera redireccionado a Firmamex.
                                </p>
                            );

                            const timeout = setTimeout(async () => {
                                window.location.href = 'https://gestorescpd.chihuahua.gob.mx/pdf/default/';
                            }, 2000);
                            return () => {
                                clearTimeout(timeout);
                            }
                        }
                    }
                }
            } else {
                setStatus(
                    <p>
                        El link no es válido o ha expirado.
                    </p>
                )
            }
        }

        verifyToken();
    }, [])

    return (
        <div className="col" style={{textAlign:'center', maxWidth:"400px", margin:'auto', marginTop:'50px'}}>
            {status}
        </div>
    )
}

export default LoginToken;