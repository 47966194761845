import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, Label, Input, FormGroup } from 'reactstrap';
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdi: '',
    regimenFiscal: ''
}

function UpdateRegimenFiscal({isOpen, invoiceId, regimenFiscalesSelect, onSaved, askFacturacion}) {

    const [data, setData] = useState(defaultData);
    const [working, setWorking] = useState(false);

    function handleTextChange(attr){
        return function(event) {
            setData({...data, [attr]:event.target.value})
        }
    }

    useEffect(() => {
        setWorking(true);
        if(invoiceId && invoiceId !== 0) {
            async function fetchInvoice() {
                const response = await httpGet(`/invoiceData/${invoiceId}`);
                if(response.data) {
                    setData({...response.data, name: response.data.name, rfc: response.data.rfc, email: response.data.email, postalCode: response.data.postalCode, cfdi: response.data.cfdi, regimenFiscal: '' });
                }
                setWorking(false);
            }
            fetchInvoice();
        } else {
            setWorking(false);
        }
    }, [invoiceId]);

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), data);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <Form onSubmit={handleSubmit}>
                    <ModalHeader>{
                        !askFacturacion ? <React.Fragment>
                                Actualización de 'Régimen fiscal'
                            </React.Fragment> : <React.Fragment>
                                Nuevo contacto de facturación
                            </React.Fragment>
                        }
                    </ModalHeader>
                    <ModalBody>
                        {
                            !askFacturacion ? <React.Fragment>
                                Hemos detectado que su contacto de facturación no cuenta con un régimen fiscal actualizado.
                                <br/>
                                <br/>
                                El contacto de facturación activo se cargará a continuación y podrá editarlo. <b>Todos los campos son requeridos.</b>
                            </React.Fragment> : <React.Fragment>
                                Hemos detectado que no cuenta con un contacto de facturación. <b>Todos los campos son requeridos.</b>
                            </React.Fragment>
                        }
                        <br/>
                        <br/>                                        
                        <FormGroup>
                        <Label for="name">Razón social</Label>
                            <Input type="text" id="name" value={data.name} onChange={handleTextChange('name')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="rfc">RFC</Label>
                            <Input type="text" id="rfc" value={data.rfc} onChange={handleTextChange('rfc')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="nombreFacturacion">Nombre</Label>
                            <Input type="text" id="nombreFacturacion" value={data.contactName} onChange={handleTextChange('contactName')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email">Correo electrónico</Label>
                            <Input type="email" id="emailFacturacion" value={data.contactEmail} onChange={handleTextChange('contactEmail')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="telefonoFacturacion">Telefono</Label>
                            <Input type="number" id="telefonoFacturacion" value={data.contactPhone} onChange={handleTextChange('contactPhone')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="postalCode">Código postal</Label>
                            <Input type="text" id="postalCode" value={data.postalCode} onChange={handleTextChange('postalCode')} required/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="CFDI">Uso del CFDI</Label>
                            <Input id="cfdi" type="select" value={data.cfdi} onChange={handleTextChange('cfdi')} required>
                                <option value=""></option>
                                <option value="Adquisición de mercancías">Adquisición de mercancías</option>
                                <option value="Devoluciones, descuentos o bonificaciones">Devoluciones, descuentos o bonificaciones</option>
                                <option value="Gastos en general">Gastos en general</option>
                                <option value="Construcciones">Construcciones</option>
                                <option value="Mobiliario y equipo de oficina por inversiones">Mobiliario y equipo de oficina por inversiones</option>
                                <option value="Equipo de transporte">Equipo de transporte</option>
                                <option value="Equipo de cómputo y accesorios">Equipo de cómputo y accesorios</option>
                                <option value="Dados, troqueles, moldes, matrices y herramental">Dados, troqueles, moldes, matrices y herramental</option>
                                <option value="Comunicaciones telefónicas">Comunicaciones telefónicas</option>
                                <option value="Comunicaciones satelitales">Comunicaciones satelitales</option>
                                <option value="Otra maquinaria y equipo">Otra maquinaria y equipo</option>
                                <option value="Honorarios médicos, dentales y gastos hospitalarios.">Honorarios médicos, dentales y gastos hospitalarios</option>
                                <option value="Gastos médicos por incapacidad o discapacidad">Gastos médicos por incapacidad o discapacidad</option>
                                <option value="Gastos funerales">Gastos funerales</option>
                                <option value="Donativos">Donativos</option>
                                <option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)">Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</option>
                                <option value="Aportaciones voluntarias al SAR">Aportaciones voluntarias al SAR</option>
                                <option value="Primas por seguros de gastos médicos.">Primas por seguros de gastos médicos</option>
                                <option value="Gastos de transportación escolar obligatoria.">Gastos de transportación escolar obligatoria</option>
                                <option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</option>
                                <option value="Pagos por servicios educativos (colegiaturas)">Pagos por servicios educativos (colegiaturas)</option>
                                <option value="Por definir">Por definir</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="regimenFiscal">Régimen fiscal</Label>
                            <Input id="regimenFiscal" type="select" value={data.regimenFiscal} onChange={handleTextChange('regimenFiscal')} required>
                                <option value=""></option>
                                {regimenFiscalesSelect}
                            </Input>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" disabled={working} color="primary">Guardar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
}
export default UpdateRegimenFiscal;